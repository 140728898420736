<template>
	<div class="chat">
		<!-- <remote-js
	      :jsUrl="'https://maps.googleapis.com/maps/api/js?key='+googlekey+'&libraries=places&language=en&callback='+initMap"
	      :js-load-call-back="settinedress"
	     v-if="googleReset"></remote-js> -->
	    <!-- <remote-js
	      jsUrl="https://media.twiliocdn.com/sdk/js/chat/v3.4/twilio-chat.min.js"
	      :js-load-call-back="chatcallback"
	    ></remote-js> -->
		<div class="map" id="Gmap"></div>
		<div class="shootbox">
			<h1>
				Moving Status：{{InfoStatus}}
				<p style="color:rgba(0,0,0,.7);font-size: 14px;" v-if="Infodata.taskStatus.value==5&&Infodata.route=='A'">Estimated arrival time：{{time}}</p>
			</h1>
			<div class="chatbox" id="chatbox">
				<div class="chatli " :class="data.username==userIdentity?'right':'left'" v-for="(data,index) in messagelist">
					<span>{{data.body}}</span>
				</div>
				<!--<div class="chatli right">
					<span>请问出发了吗？</span>
				</div>-->
			</div>
			<div class="chatbottom">
				<div class="chatbottomleft"  v-if="!keyboard">
					<div class="commonli" @click="sendMessage('Hi，I am ready.')">
						Hi，I am ready.
					</div>
					<div class="commonli" @click="sendMessage('Hi, have you set off？')">
						Hi, have you set off？
					</div>
				</div>
				<div class="chatbottomleft" v-else>
					<form class="search-block" action="javascript:void 0">
						<input type="text" class="keyinput" v-model="txtInput">
					</form>
				</div>
				<img src="img/keyboard.png" class="keyboard" @click="keyboardtab" v-if="!keyboard">
				<div v-else>
					<img src="img/switch.png" class="keyboard" @click="keyboardtab">
					<img src="img/fly.png" class="keyboard" @click="tapToSearch">
				</div>
			</div>
		</div>
		<div class="evaluate" v-if="starStatus==1">
			<div class="evaluatehread">
				<h1>
					The task has been completed. Please rate your moving service
				</h1>
				<div class="evaluatestar">
					<i class="evaluateli"  v-for="(i,index) in starlist" :class="star>index?'active':''" :key="index" @click="clickStars(index)">
					</i>
				</div>
			</div>
			<div class="evaluafooter">
				<a href="javascript:;" @click="Submitstar">
					Submit evaluation
				</a>
			</div>
		</div>
		<div class="evaluate" v-if="starStatus==2">
			<div class="evaluatehread">
				<h1>
					Evaluation submitted
				</h1>
				<div class="evaluatestar">
					<i class="evaluateli"  v-for="(i,index) in starlist" :class="star>index?'active':''" :key="index" >
					</i>
				</div>
			</div>
			<div class="evaluafooter">
				<router-link to="/">
					IMover Website
				</router-link>
			</div>
		</div>
		<div v-bind:class="'popUpFrame '+(isOpen?'active':'')" >
	    	<div class="bg"></div>
		    <div class="loading">
	          <img src="/img/car.gif">
	          <h3>Loading...</h3>
		    </div>
	  	</div>
	  	<div class="popshow" v-if="tipsStatus">
	  		{{tipsTxt}}
	  	</div>
	</div>
</template>

<script>
	import RemoteJs from '@/components/remote.vue';
	import api from '@/api';
export default {
  name: 'chat',
  data(){
	  	return{
  		  	starlist:[0,1,2,3,4],
  		  	star:0,
  		  	starStatus:0,//0为进行中,1待评价,2已评价
  		  	tipsStatus:false,
  		  	tipsTxt:'',
	  		isOpen:true,
			keyboard: false, 
			txtInput:'',
			initMap:'',
			one:0,
			token:'',
			messageNumber:50,
			channelSID:'',
			userIdentity:'',
			InfoStatus:'',
			messagelist:[],
			messagingClient:'',
  			googlekey:'',
  			googleReset:false,
  			time:'',
  			Infodata:{
  				taskStatus:{
  					value:-1
  				},
  				route:''
  			}
	  	}
  },
  components: {
    RemoteJs
  },
   created(){
		var that=this;
		api.indexInfo().then(res => {
			if(res.data.code == 1000) {
				this.loadThirdAssets('https://maps.googleapis.com/maps/api/js?key='+res.data.data.GoogleApiKey+'&libraries=places&language=en&callback=');
				this.loadThirdAssetsNew('https://media.twiliocdn.com/sdk/js/chat/v3.4/twilio-chat.min.js');
				// that.googlekey=res.data.data.GoogleApiKey;
				// that.$nextTick(() => {
		  //      		that.googleReset = true
		  //     	});
			} else {
			}
		});
  	},
  mounted() {
	let taskId=this.utils.getUrlParam('taskId');
	this.taskId=taskId;
	this.getpositionInfo(taskId);
	this.getTime();
  },
	methods:{
		loadThirdAssetsNew(src) {
			var that=this;
			if([].slice.call(document.querySelectorAll('third')).length >= 2) {
				return;
			}
			const element = document.createElement('script');
			element.setAttribute('src', src);
			element.className = 'third';
			element.setAttribute('async', 'async');
			document.getElementsByTagName('body')[0].appendChild(element);
			element.onload = () => {
				that.chatcallback();
			};
		},
		loadThirdAssets(src) {
			var that=this;
			if([].slice.call(document.querySelectorAll('third')).length >= 2) {
				return;
			}
			const element = document.createElement('script');
			element.setAttribute('src', src);
			element.className = 'third';
			element.setAttribute('async', 'async');
			document.getElementsByTagName('body')[0].appendChild(element);
			element.onload = () => {
				that.settinedress();
			};
		},
		Submitstar(){//提交评价
			var that=this;
			if(that.star>0){
				var datascore={
					score:that.star
				}
				api.getscore(that.taskId,that.star).then(res => {
					that.tipsStatus=true;
					that.tipsTxt='Evaluated successfully';
					setTimeout(function(){
						that.tipsStatus=false;
						that.starStatus=2;
					},2000);
				})
			}else{
				that.tipsStatus=true;
				that.tipsTxt='Please rate';
				setTimeout(function(){
					that.tipsStatus=false;
				},2000);
			}
			
		},
		clickStars(i){
		    this.star = i+1;
		},
		getinit(id) {
			var that=this;	
			var bookid=this.utils.getUrlParam('taskId');
			api.getposition(id).then(res => {
				if(res.data.code == 1000) {
					that.loadRongJs(res.data.data.lat,res.data.data.lng);
				} else {
				}
				setTimeout(function(){
					that.getinit(that.taskId);
				},10000);
			});
			
		}, 
		formateDate(datetime) {
	      // let  = "2019-11-06T16:00:00.000Z"
	        function addDateZero(num) {
	            return (num < 10 ? "0" + num : num);
	        }
	        let d = new Date(datetime);
	        let formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate()) + ' ' + addDateZero(d.getHours()) + ':' + addDateZero(d.getMinutes()) + ':' + addDateZero(d.getSeconds());
	        return formatdatetime;
	    },
		getTime(){
			let that=this;
			var bookid=this.utils.getUrlParam('taskId');
			api.getarrivetime(bookid).then(res => {
				if(res.data.code == 1000) {
					that.time=that.formateDate(res.data.data.arrivetime);
				}else{
					setTimeout(function(){
						that.getTime();
					},10000);
				}
			});
		},
		getchatinit(id){//获取初始化数据
			var that=this;			
			api.getchannels(id).then(res => {
				that.channelSID=res.data.data.channelSID;
				that.userIdentity=res.data.data.userIdentity;
				that.token=res.data.data.twilioToken;
				that.connectMessagingClient(that.token);
			});
		},
		connectMessagingClient(token){//初始化聊天插件
			if (typeof Twilio !== 'undefined') {
	            Twilio.Chat.Client.create(token).then(client => {  // Use client
	                this.TwilioClient = client;
	                client.getChannelBySid(this.channelSID).then(myclient => {
	                    myclient.getMessages(this.messageNumber).then(messages => {
	                         console.log(messages);
	                        for (const item of messages.items){
	                            this.messagelist.push({
	                                username: item.author,
	                                // date: dateFormatter.getTodayDate(message.timestamp),
	                                date: item.timestamp,
	                                body: item.body
	                            });
	                        }
	                        this.scrollToBottom();
	                        this.isOpen=false;
	                    });
	                    this.initChannelEvents(myclient);
	                });
	            });
	        }
		},
		initChannelEvents(currentChannel) {//初始化聊天事件
	        currentChannel.on('messageAdded', this.messageAdded);
	        currentChannel.on('typingStarted', this.showTypingStarted);
	        currentChannel.on('typingEnded', this.hideTypingStarted);
	        currentChannel.on('memberJoined', this.notifyMemberJoined);
	        currentChannel.on('memberLeft', this.notifyMemberLeft);
	        currentChannel.qtis = this;
	        this.messagingClient = currentChannel;
	    },
	    showTypingStarted(member) {
	        console.log(member.identity + ' is typing...');
	    },
	    hideTypingStarted(member) {
	        console.log(member.identity + ' is typing...');
	    },
	    notifyMemberJoined(member) {
	        console.log('joined');
	        this.notify(member.identity + ' joined the channel');
	    },
	    notifyMemberLeft(member) {
	        console.log('left');
	        this.notify(member.identity + ' left the channel');
	    },
	    scrollToBottom() {
	       this.$nextTick(() => {
	         var container = this.$el.querySelector("#chatbox");
	         container.scrollTop = container.scrollHeight;
	       })
	    },
	    messageAdded(message) {	   
	    	this.index=message.state.index;
	        this.messagelist.push({
	            username: message.state.author,
	            date: message.state.timestamp,
	            body: message.state.body
	        });
	        this.scrollToBottom();
	    },
		getpositionInfo(id){
			var that=this;
			api.getpositionInfo(id).then(res => {
				if(res.data.code == 1000) {
					that.Infodata=res.data.data;
					that.InfoStatus=res.data.data.taskStatus.text;
					if(res.data.data.taskStatus.value==20){
						if(res.data.data.score>0){
							that.starStatus=2;
							that.star=res.data.data.score;
						}else{
							that.starStatus=1;
						}
					}
				} else {
				}
				setTimeout(function(){
					that.getpositionInfo(that.taskId);
				},30000);
			});
		},
		settinedress(){
			var  that=this;
  			var taskId=this.utils.getUrlParam('taskId');
  			that.taskId=taskId;
			that.getinit(taskId);
		},
		chatcallback(){
			var  that=this;
  			var bookid=this.utils.getUrlParam('taskId');
			that.getchatinit(bookid);
		},
		loadRongJs(lat,lng) {
			var uluru = {
		            lat:lat,
		            lng: lng
		        };
		        var map = new google.maps.Map(document.getElementById('Gmap'), {
		            center: uluru,
		            zoom: 12,
		            scrollwheel: false,
		            navigationControl: false,
		            mapTypeControl: false,
		            scaleControl: false,
		            styles: [{
		                "elementType": "geometry",
		                "stylers": [{
		                    "color": "#f5f5f5"
		                }]
		            }, {
		                "elementType": "labels.icon",
		                "stylers": [{
		                    "visibility": "off"
		                }]
		            }, {
		                "elementType": "labels.text.fill",
		                "stylers": [{
		                    "color": "#616161"
		                }]
		            }, {
		                "elementType": "labels.text.stroke",
		                "stylers": [{
		                    "color": "#f5f5f5"
		                }]
		            }, {
		                "featureType": "administrative.land_parcel",
		                "elementType": "labels.text.fill",
		                "stylers": [{
		                    "color": "#bdbdbd"
		                }]
		            }, {
		                "featureType": "poi",
		                "elementType": "geometry",
		                "stylers": [{
		                    "color": "#eeeeee"
		                }]
		            }, {
		                "featureType": "poi",
		                "elementType": "labels.text.fill",
		                "stylers": [{
		                    "color": "#757575"
		                }]
		            }, {
		                "featureType": "poi.park",
		                "elementType": "geometry",
		                "stylers": [{
		                    "color": "#e5e5e5"
		                }]
		            }, {
		                "featureType": "poi.park",
		                "elementType": "labels.text.fill",
		                "stylers": [{
		                    "color": "#9e9e9e"
		                }]
		            }, {
		                "featureType": "road",
		                "elementType": "geometry",
		                "stylers": [{
		                    "color": "#ffffff"
		                }]
		            }, {
		                "featureType": "road.arterial",
		                "elementType": "labels.text.fill",
		                "stylers": [{
		                    "color": "#757575"
		                }]
		            }, {
		                "featureType": "road.highway",
		                "elementType": "geometry",
		                "stylers": [{
		                    "color": "#dadada"
		                }]
		            }, {
		                "featureType": "road.highway",
		                "elementType": "labels.text.fill",
		                "stylers": [{
		                    "color": "#616161"
		                }]
		            }, {
		                "featureType": "road.local",
		                "elementType": "labels.text.fill",
		                "stylers": [{
		                    "color": "#9e9e9e"
		                }]
		            }, {
		                "featureType": "transit.line",
		                "elementType": "geometry",
		                "stylers": [{
		                    "color": "#e5e5e5"
		                }]
		            }, {
		                "featureType": "transit.station",
		                "elementType": "geometry",
		                "stylers": [{
		                    "color": "#eeeeee"
		                }]
		            }, {
		                "featureType": "water",
		                "elementType": "geometry",
		                "stylers": [{
		                    "color": "#c9c9c9"
		                }]
		            }, {
		                "featureType": "water",
		                "elementType": "labels.text.fill",
		                "stylers": [{
		                    "color": "#9e9e9e"
		                }]
		            }]
		        });
		        var marker = new google.maps.Marker({
		            position: uluru,
		            map: map
		        });
	  },
		keyboardtab(){
			this.keyboard=!this.keyboard;
		},
		tapToSearch(msg){
			this.sendMessage(this.txtInput);
			this.txtInput='';
		},
		channelmessage(index,msg){
			var that=this;	
			if(that.messagelist.length>0){
				api.channelmessage(that.channelSID,msg).then(res => {
					console.log('更新最后一个信息展示');
				});
			}			
			this.index=index;
			api.lastchannelmessage(index,that.channelSID,that.userIdentity).then(res => {
				console.log('更新最后一个信息展示');
			});
		},
		sendMessage(msg){//发送聊天信息
			var that=this;	
			if (msg === ''){
	            return;
	        }
			that.messagingClient.sendMessage(msg).then(res => {
				that.channelmessage(res,msg);
			});;
		}
	}
}
</script>

<style>
	.map{
		position: fixed !important;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #000;
		z-index: 10;
		width: 100%;
		height: 100%;
	}
	.keyboard{
		width: 32px;
		height: 32px;
		margin-left: 5px;
	}
	.shootbox{
		position: fixed;
		bottom: 0;
		z-index: 11;
		width: 100%;
		border-radius: 8px 8px 0 0;
		background: #fff;
		padding: 0 20px 20px 20px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}
	.shootbox h1{
		padding: 15px 0;
		color: #333;
		font-size: 16px;
	}
	.chatli{
		display: flex;
		margin-bottom: 5px;
	}
	.left {
	    flex-direction: row;
	}
	
	.right {
	    flex-direction: row-reverse;
	}
	.chatli span{
		border-radius: 30px;
		background: #F3F3F3;
		padding: 5px 20px;
		font-size: 12px;
		color: #333;
	}
	.chatbottom{
		display: flex;
		padding-top:10px;
		align-items: center;
		justify-content: space-between;
	}
	.chatbottomleft{
		display: flex;
		flex: 1;
		padding-right: 10px;
	}
	.chatbottomleft .commonli{
		border: 1px #D22E35 solid;
		border-radius: 30px;
		padding: 8px 10px;
		font-size: 12px;
		color: #D22E35;
		margin-right: 15px;
	}
	.keyinput{
		height: 35px;
		background: #f3f3f3;
		border-radius: 30px;
		width: 100%;
		padding: 0;
		border: 0;
		padding: 0 30px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}
	.chatbox{
		max-height: 300px;
		overflow-y: auto;
	}
	.evaluate{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		align-content: center;
		z-index: 199;
	}
	.evaluafooter{
		padding: 50px 0;
	}
	.evaluafooter a{
		width:208px;
		height:41px;
		background:rgba(210,46,53,1);
		box-shadow:0px 2px 5px 0px rgba(222,72,84,1);
		border-radius:24px;
		color: #fff;
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
	}
	.evaluatehread{
		flex: 1;
		display: flex;
    	justify-content: center;
		align-items: center;
		flex-flow: column;
	}
	.evaluatestar{
		padding: 10px 0;
	}
	.evaluateli{
		width: 35px;
		height: 35px;
		background: url(../../public/img/Starnone.png) no-repeat;
		background-size:35px;
		display: inline-block;
	}
	.evaluateli.active{
		width: 35px;
		height: 35px;
		background: url(../../public/img/Starshow.png) no-repeat;
		background-size:35px;
	}
	.evaluatehread h1{
		font-size: 16px;
		color: #000;
		padding-bottom: 15px;
	}
	.popshow{
		width: 150px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0,0,0,.6);
		color: #fff;
		font-size: 18px;
		position: fixed;
		top: 50%;
		margin-top: -25px;
		left: 50%;
		margin-left: -75px;
		z-index: 999;
		border-radius: 4px;
	}
</style>