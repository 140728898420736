<template>
  <div class="codeBox">
  		<h1>
  			Here is your iMover Voucher
  		</h1>
  		<img :src="Imgurl">
  		<p>{{codeNum}}</p>
  </div>
</template>

<script>
	import api from '@/api';
import homebottom from '@/components/homebottom.vue'
// @ is an alias to /src

export default {
  name: 'Code',
  data(){
  	return{
  		Imgurl:'',
  		codeNum:''
  	}
  },
  components: {
    homebottom
  },
  methods: {
  	codeImg(e){
  		var that=this;
  		window.popUpFrame.Loading(api.getcode(e)).then(res => {
					if(res.data.code == 1000) {
						that.Imgurl=res.data.data.qrcodePath;
						console.log(res.data.data.qrcodePath);
					} else {
					}
			});
  	}
  },
  mounted() {
  	var that=this;
  	this.codeNum=this.$route.params.id;
		this.$nextTick(function(){
	  	that.codeImg(this.$route.params.id);
		})
  	console.log(this.$route.params.id)
	},
}
</script>

<style scoped="scoped" lang="less">
	/deep/ .HreadNav{
		display: none;
	}
	.codeBox{
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		h1{
			font-size: 22px;
			color: #000000;
			margin-bottom: 10px;
		}
		img{
			width: 150px;
			height: 150px;
		}
		p{
			padding-top: 10px;
			font-size: 16px;
			color: #000;
		}
	}
</style>