<template>
  <div class="about">
  		<div class="AboutTxt">
  			<div class="container">
  				<div class="FullPiceBox">
  					<h1>
  						Quick quote
  					</h1>
  					<p>
  						This is just a quick guide based on average moves like yours. To get a more accurate quote continue on for a full quote.
  					</p>
  					<div class="FullPiceNum">
							<img src="/img/fullimg_03.jpg">
							<div class="FullPiceTxt">
								<h1>$147-$213</h1>
								<p>1h to 1hr 30 mins</p>
								<p>1 mover & 1 small truck</p>
							</div>
  					</div>
  					<div class="FullEdit">							
							<div class="FullBtnBox">
        				<a href="javascript:;" class="calendar">
        					<span class="icon"></span><span>Make a booking</span>
        				</a>
        				<a href="javascript:;" class="email">
        					<span class="icon"></span><span>Email me the quote</span>
        				</a>
        				<!-- <a href="javascript:;" class="email">
        					<span class="iconnew"></span><span>Continue to full quote</span>
        				</a> -->
							</div>
  					</div>
  				</div>
  			</div>
  		</div>
    	<homebottom />
  </div>
</template>

<script>
import homebottom from '@/components/homebottom.vue'
// @ is an alias to /src

export default {
  name: 'About',
  components: {
    homebottom
  },
  mounted() {
	},
}
</script>