<template>
  <div class="about">
  		<div class="AboutTxt">
  			<div class="container">
  				<div class="FullPiceBox">
					<h1>
						Please review your booking details
					</h1>
					<!-- <div v-else>
						<h1>
							{{quotetype==1?'Quick quote':'Full price'}} 
						</h1>
						<p>
							Here’s our full quote for your move to {{$store.state.getlist[$store.state.getlist.length-1].inputtextall}}
						</p>
					</div>	 -->
  					<div class="FullPiceNum">
							<img src="/img/fullimg_03.jpg">
							<div class="FullPiceTxt">
								<h1 v-if="quotetype!=1">${{dataval.minAmount | tofixednew}}-${{dataval.maxAmount | tofixednew}}</h1>
								<p>{{dataval.minHour | tofixed}} to {{dataval.maxHour | tofixed}} hours</p>
								<!-- <p>{{dataval.mover}} mover & {{dataval.vechileNumber}} {{dataval.vechileModel}} truck</p> -->
								<p>{{dataval.mover}} {{dataval.mover>1?'movers':'mover'}} & {{dataval.vechileModel}}</p>
							</div>
  					</div>
  					<div class="FullEdit">
							<div class="FullEditHread">
								<span>Location & Time</span>
								<a href="javascript:;" @click="EditBtn('location')">Edit</a>
							</div>
							<!--房子类型-->
							<div class="FullEditBody">
								<div class="FullBodyLi">
									<div class="FullLiBox" v-for="(item,index) in $store.state.getlist">
										<div class="FullBoxList">
											<label v-if="index==0">
												<!-- <span v-if="$store.state.getlist.length>2">From</span>
												<span v-else>Pick-up</span> -->
												From
											</label>
											<label v-else-if="index==$store.state.getlist.length-1">to</label>
											<label v-else>via</label>
											<div>{{item.inputtextall}}</div>
										</div>
										<div class="FullBoxList">
											<label>Type</label>
											<div :class="item.house=='office'?'bigcapit':''">{{item.house}}</div>
										</div>
									</div>
									<div class="FullLiBox">
										<div class="FullBoxList">
											<label>When</label>
											<div>{{orderInfo.Timedata.pickedDate}}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="FullEditHread" v-if="quotetype!=1">
								<span>Access</span>
        						<a href="javascript:;" @click="EditBtn('Access')">Edit</a>
							</div>
							<div class="FullEditBody" v-if="quotetype!=1">
								<div class="FullBodyLi">
									<div class="FullLiBox" v-for="(Item,index) in orderInfo.getlist">
										<div class="FullBoxList">
											<label v-if="index==0">
												<!-- <span v-if="orderInfo.getlist.length>2">From</span>
												<span v-else>Pick-up</span> -->
												From
											</label>
											<label v-else-if="index==orderInfo.getlist.length-1">to</label>
											<label v-else>via</label>
											<div>
												<p>
													{{Item.house}}-{{Item.floor | toone}}
													<span v-if="Item.house=='House'">Storey</span>
													<span v-if="(Item.house=='Apartment'||Item.house=='office')">Floor</span>
												</p>
												<p>Truck parking within {{Item.rice}}M</p>
											</div>
										</div>
									</div>
								</div>
							</div>
  						<div class="FullEditHread">
								<span>Contact Details</span>
							<a href="javascript:;" @click="EditBtn('Contact')">Edit</a>
							</div>
							<div class="FullEditBody">
								<div class="FullBodyLi">
									<div class="FullLiBox">
										<div class="FullBoxList">
											<label>Name</label>
											<div>
												{{$store.state.contact.Contact}}
											</div>
										</div>
										<div class="FullBoxList">
											<label>Phone</label>
											<div>
												{{$store.state.contact.Phone}}
											</div>
										</div>
										<div class="FullBoxList">
											<label>Email</label>
											<div>
												{{$store.state.contact.Email}}
											</div>
										</div>
									</div>
								</div>
							</div>
  						<div class="FullEditHread" v-if="quotetype!=1">
								<span>Move Items</span>
        				<a href="javascript:;" @click="EditBtn('Items')">Edit</a>
							</div>
							<div class="FullEditBody" v-if="quotetype!=1">
								<div class="FullBodyLi">
									<div class="FullLiBox">
										<div class="FullBoxList" v-for="(Item,index) in orderInfo.getItemListitem" v-if="Item.sel">
											<label>{{Item.Name}}</label>
											<div v-for="(secondItem,index) in Item.Items" v-if="secondItem.sel">
												<p>{{secondItem.Name}}</p>
												<p v-for="(lastItem,index) in secondItem.Items" v-if="lastItem.num>0">{{lastItem.ItemName}}X{{lastItem.num}}</p>
											</div>
											<div  v-for="(secondItem,index) in Item.Items" v-if="secondItem.num>0">
												<p>{{secondItem.ItemName}}X{{secondItem.num}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="FullEditHread">
								<span>Our Bit</span>
        				<!--<a href="javascript:;">Edit</a>-->
							</div>
							<div class="FullEditBody">
								<div class="FullBodyLi">
									<div class="FullLiBox">
										<div class="FullBoxList">
											<label>Package</label>
											<div>
												<!-- <p>{{orderInfo.processdata.GetItem.Mover}} Movers & {{orderInfo.processdata.GetItem.vechileNumber}} {{dataval.vechileModel}} at {{orderInfo.Timedata.price}}/h</p> -->
												<p>{{orderInfo.processdata.GetItem.Mover}} {{orderInfo.processdata.GetItem.Mover>1?'Movers':'Mover'}} & {{dataval.vechileModel}} at {{orderInfo.Timedata.price}}/h</p>
											</div>
										</div>
										<div class="FullBoxList">
											<label>Move Time</label>
											<div>
												<p>{{dataval.minHour | tofixed}} to {{dataval.maxHour | tofixed}} hours</p>
												<!--<p>{{$store.state.processdata.GetItem.MinHour}} to {{$store.state.processdata.GetItem.MaxHour}} hours</p>-->
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="FullEditHread">
								<span>Comment</span>
							</div>
							<textarea class="fullText" placeholder="Anything else we should know?" v-model="$store.state.contact.Remark"></textarea>
							<div class="FullCheck">
								<span class="CheckIcon" :class="IsCheck?'active':''" @click="CheckBtn()"></span>I agree to the <router-link target="_blank" to="/termstndconditions" class="red marginleft10">&nbsp;&nbsp;terms and conditions </router-link>
							</div>
							<p class="redtxt" v-if="IsCheckstate">Please agree with the terms and conditions before booking</p>
							<div class="FullBtnBox">
        				<a href="javascript:;" class="calendar" @click="next()">
        					<span class="icon"></span><span>Make a booking</span>
        				</a>
        			<!-- 	<a href="javascript:;" @click="fullQuote(3)" class="email" v-if="continuenum!=1">
        					<span class="icon"></span><span>Continue to full quote</span>
        				</a> -->
							</div>
  					</div>
  				</div>
  			</div>
  		</div>
    	<homebottom />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import homebottom from '@/components/homebottom.vue'
// @ is an alias to /src

export default {
  name: 'About',
  components: {
    homebottom
  },
  data(){
	  return{
			IsCheck: false, 
			IsCheckstate:false,
			// orderInfo:this.$store.state,
			dataval:{},
			quotetype:0
  	}
  },
  filters:{
		tofixednew:function (value) {
			return Number(value).toFixed(0);
		},
		tofixed:function (value) {
			return Number(value).toFixed(1);
		},
		toone:function(val){
			if(val=='3FM'){
				return '3+';
			}
			return val.substring(0,1);
		}
	},
	computed: mapState({
	  continuenum: (state) => state.continuenum,
	  orderInfo: (state) => state,
	}),
  mounted() {
  	var that=this;
  	this.$nextTick(function(){
			this.init();
		})
	},
	methods:{
		async init(){
			var type=2;
			this.quotetype=this.$route.query.quotetype?this.$route.query.quotetype:0;
			this.dataval=await this.utils.getPice(type,this.quotetype);				
		},
		CheckBtn(){
			this.IsCheck=!this.IsCheck;
			this.IsCheckstate=false;
		},
		fullQuote(name){
			this.$store.dispatch('getContinueFun',1);
			this.$store.dispatch('processfullpiceFun',name);
			this.$store.dispatch('setisshowFun',true);
			this.$router.push('/getquote/processparking');
		},
		Info(){
			var that=this;
		},
		EditBtn(Txt){
			var that=this;
			this.$store.dispatch('progressiconFun',false);
			switch(Txt){
				case "location":
					this.$router.push({ 
						path: '/getquote/processfrom',
						query: {
							'Edit':'update',
							'quotetype':that.quotetype,
						}
					});
					break;
				case "Access":	
					this.$store.dispatch('processfullpiceFun');
					this.$store.commit('fullItemEdit',true);
					this.$router.push({ 
							path: '/getquote/processparking'
					});
					break;
				case "Contact":	
					this.$router.push({ path: '/getquote/processcontact',
						query:{
							'quotetype':that.quotetype,
						}
					});
					break;
				case "Items":
					if(this.$store.state.processdata.SelHouse=="home"){
						this.$store.dispatch('getLayoutFun',11);
						this.$router.push({ 
							path: '/getquote/processitems',
							query: {
								'Edit':'update'
							}
						});
					}else{
						this.$store.dispatch('getLayoutFun',12);
						this.$router.push({ 
							path: '/getquote/processbusinessitems',
							query: {
								'Edit':'update'
							}
						});
					}
					break;
			}
		},
		next(){
			if(this.IsCheck){
				this.$store.commit('fullItemEdit',false);
				this.$store.dispatch('getsubmitTypeFun',2);
				this.utils.Getsubmit();
			}else{
				this.IsCheckstate=true;
			}
		},
		fullemail(){
			if(this.IsCheck){
				this.$store.dispatch('getsubmitTypeFun',1);
				this.utils.Getsubmit('email');
			}else{
				this.IsCheckstate=true;
			}
		}
	}
}
</script>
<style scoped="scoped">
	.marginleft10{
		margin-left: 3px;
	}
	.FullPiceBox p.redtxt{
		color: #D22E35;
		font-size: 18px;
		text-align: left;
	}
	.bigcapit{
		text-transform:capitalize;
	}
</style>