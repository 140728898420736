<template>
	<div class="ProcessBody">
		<div class="HomeBody NewPadd">
	  		<div class="container">
	  			<h1 class="ProcessLittle nomorlpadd" >
	  				Your {{processdata.inputtextall}} {{processdata.house}}
				</h1>
				<p class="ProcessSubheading">
					is there an elevator we can use?
				</p>
				<div class="Processzero">
					<a href="javascript:;" :class="(list[index].docklands||list[index].docklands===null)?'':'active'" @click="ProcessBtn(false)">
						No
					</a>
					<a href="javascript:;" :class="list[index].docklands?'active':''" @click="ProcessBtn(true)">
						Yes
					</a>
				</div>
				<!--<img class="secondImg" src="/img/secondImg.jpg">-->
	    	</div>
	  	</div>
	</div>
</template>

<script>
	import { mapState, mapMutations } from "vuex";
	export default {
		name: 'HelloWorld',
		props: {
		},
		data() {
			return {				
				processdata:{},
			}
		},
		computed: mapState({
		  list: (state) => state.getlist,
		  index: (state) => state.parkingindex,
		}),
		methods:{
			ProcessBtn(Docktype){
	//			this.$emit('func',fromtype);
				this.$store.dispatch('fatherDockMethodFun',Docktype);
			}
		},
		mounted() {
			this.processdata=this.$store.state.getlist[this.$store.state.parkingindex];
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
