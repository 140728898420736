<template>
	<div class="ProcessBody">
		<div class="HomeBody NewPadd">
	  		<div class="container">
	  			<h1 class="ProcessLittle">
	  				I need to move to
				</h1>
				<div class="ProcessSecondBox">
					<a href="javascript:;" data-txt="Apartment" @click="ProcessBtn('Apartment')" :class="tohouse=='Apartment'?'active':''">
						Apartment
					</a>
					<a href="javascript:;" data-txt="House" @click="ProcessBtn('House')" :class="tohouse=='House'?'active':''">
						House
					</a>
					<a href="javascript:;" data-txt="facility" @click="ProcessBtn('facility')" :class="tohouse=='facility'?'active':''">
						Storage facility
					</a>
				</div>
				<!--<img class="secondImg" src="/img/secondImg.jpg">-->
	    	</div>
	  	</div>
	</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    tohouse:String
  },
  data() {
		return {
			Layout: 1, 
			hread:1,
			comName:'processto'
		}
	},
	methods:{
		ProcessBtn(fromtype){
			this.$store.dispatch('getMsgFormtoFun',fromtype);
			this.$router.push({path:'/getquote/processaddress',params:{fromtype:fromtype}});
		}
	},
	mounted() {
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
