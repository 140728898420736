<template>
  <div>
    <div class="dateClass">
      <div v-if="datePickerShow" class="date-picker-container">
    		<!--<div class="dateClose" @click.stop="closePicker"></div>-->
        <p style="padding: 10px 0;">
          <span class="showDate">
            <!-- 这里用了element的左箭头图标用于点击上个月按钮 -->
            <a href="javascript:;" @click.stop="lastMonth" class="monthBtn">
             <
            </a>
            <!-- 以下为展示当前年月 -->
            <span class="MarginRight">{{month}}</span>
            <span >{{currentDate.year}}</span>
            <!-- 下个月按钮 -->
            <a href="javascript:;" @click.stop="nextMonth" class="monthBtn">
              >
            </a>
          </span>
          <!-- 关闭日历弹窗按钮 -->
          <!--<div class="closebtn" @click.stop="closePicker">
          	X
          </div>-->
        </p>
        <!-- 日历周展示 -->
        <div class="week">
          <div v-for="(item, index) in week" :key="index">{{item}}</div>
        </div>
        <!-- 日及其对应价格展示 -->
        <div class="dates">
          <div v-for="(item, index) in priceDates" :class="{today: item.isPrice, noblank: true,active:(item.date==indexDate&&indexmonth==(currentDate.month+1))}"
            @click.stop="pickDate(item.date, item.price)" :key="index">
            <div>
	            <span>{{item.date}}</span><br>
	            <span class="picestyle" v-if="item.price">{{item.price}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api';
export default {
  name: 'myCalendar',
  props: {
    // 接收父组件传递是否展示日历弹窗
    datePickerShow: {
      type: Boolean,
      default: false
    },
    // 接收父组件传递日期价格数据
//  feeProps: {
//    type: Object,
//    default: function () {
//      return {}
//    }
//  }
  },
  data: function () {
    return {
      pickedDate: '',
      price: '',
      week: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      priceDates: [],
      indexDate:null,
      indexmonth:null,
      feeProps:{},
      currentDate: {
        year: '',
        month:1,
        date: ''
      }
    }
  },
  computed: {
    // 计算并展示选择的月份
    month: function () {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[this.currentDate.month]
    }
  },
  methods: {
    // 默认日期显示
    openPicker: function (feeLis) {
		// console.log(Object.keys(feeLis)[0]);
      this.priceDates = []
      const now = new Date(Object.keys(feeLis)[0])
      this.currentDate.year = now.getFullYear()
      this.currentDate.month = now.getMonth()
      this.currentDate.date = now.getDate()
      now.setDate(1)
      // now已经变为当月第一天
      const firstDay = now.getDay() // 当月第一天是星期几
      // 当月最后一天是几号
      const lastDate = new Date(now.getFullYear(),now.getMonth()+1,0).getDate();
      
      // 空出1号是星期几前面的那几天：例如日期从周日排到周一，1号是周二，则前面会空出周日和周一两个空日期
      for (let i = 0; i < firstDay; i++) {
        this.priceDates.push({ date: '', price: '', isPrice: false })
      }
      // 当月日期往后排
      for (let i = 1; i < lastDate + 1; i++) {
        this.priceDates.push({
          date: '' + i,
          price: '',
          isPrice: false
        })
        
        // 循环日期费用，判断年月日与当前是否相等，相等则显示价格与对应样式
        for (let key in feeLis) {
          let dateArr = key.split('-')
          if (dateArr[0] === (this.currentDate.year + '') && (parseInt(dateArr[1]) + '') === (this.currentDate.month + 1 + '') && (parseInt(dateArr[2]) + '') === (i + '')) {
            this.priceDates[i - 1 + firstDay].price = '$' + feeLis[key]
            this.priceDates[i - 1 + firstDay].isPrice = true
          }
        }
      }
    },
    // 上个月
    lastMonth: function () {
      if (this.currentDate.month !== 0) {
        this.currentDate.month--
      } else {
        this.currentDate.month = 11
        this.currentDate.year--
      }
      const now = new Date()
      now.setFullYear(this.currentDate.year, this.currentDate.month, 1)
      const firstDay = now.getDay() // 当月第一天是星期几
      now.setFullYear(this.currentDate.year, this.currentDate.month + 1, 0)
      const lastDate = now.getDate() // 当月最后一天是几号
      this.priceDates = []
      // 下面同理
      for (let i = 0; i < firstDay; i++) {
        this.priceDates.push({ date: '', price: '', isPrice: false })
      }
      for (let i = 1; i < lastDate + 1; i++) {
        this.priceDates.push({
          date: '' + i,
          price: ' ',
          isPrice: false
        })
        for (let key in this.feeProps) {
          let dateArr = key.split('-')
          if (dateArr[0] === (this.currentDate.year + '') && (parseInt(dateArr[1]) + '') === (this.currentDate.month + 1 + '') && (parseInt(dateArr[2]) + '') === (i + '')) {
            this.priceDates[i - 1 + firstDay].price = this.feeProps[key]
            this.priceDates[i - 1 + firstDay].isPrice = true
          }
        }
      }
    },
    // 下个月
    nextMonth: function () {
      if (this.currentDate.month !== 11) {
        this.currentDate.month++
      } else {
        this.currentDate.month = 0
        this.currentDate.year++
      }
      const now = new Date()
      now.setFullYear(this.currentDate.year, this.currentDate.month, 1)
      const firstDay = now.getDay() // 当月第一天是星期几
      now.setFullYear(this.currentDate.year, this.currentDate.month + 1, 0)
      const lastDate = now.getDate() // 当月最后一天是几号
      this.priceDates = []
      for (let i = 0; i < firstDay; i++) {
        this.priceDates.push({ date: '', price: '', isPrice: false })
      }
      for (let i = 1; i < lastDate + 1; i++) {
        this.priceDates.push({
          date: '' + i,
          price: ' ',
          isPrice: false
        })
        console.log(this.feeProps);
        for (let key in this.feeProps) {
          let dateArr = key.split('-')
          if (dateArr[0] === (this.currentDate.year + '') && (parseInt(dateArr[1]) + '') === (this.currentDate.month + 1 + '') && (parseInt(dateArr[2]) + '') === (i + '')) {
            this.priceDates[i - 1 + firstDay].price = '$' + this.feeProps[key]
            this.priceDates[i - 1 + firstDay].isPrice = true
          }
        }
      }
    },
    // 关闭弹窗发布事件
    closePicker: function () {
      this.$emit('feeClose', false)
    },
    // 记录点击的日期，根据是否需要使用
    pickDate: function (date, price) {
		console.log(price);
		if(price&&price!=' '){
		  this.pickedDate = this.currentDate.year + '-' + (this.currentDate.month + 1) + '-' + date
		  this.price = price;
		  var pickDateTxt={};
		  pickDateTxt.price=price;
		  pickDateTxt.pickedDate=this.pickedDate;
		  this.indexDate=date;
		  this.indexmonth=this.currentDate.month + 1;
		  this.$emit('subimeTime', pickDateTxt)
		}
    }
  },
  mounted() {
  	var that=this;
  	if(this.$store.state.Timedata.pickedDate){
  		var index=this.$store.state.Timedata.pickedDate.split('-');
	  	this.indexDate=index[2];
	  	this.indexmonth=index[1];
  	}  	
  	let Codeid=that.$store.state.processdata.GetItem.Code;
	that.$nextTick(() => {
		window.popUpFrame.Loading(api.monthdate(Codeid)).then(res => {
			if(res.data.code == 1000) {
				let monthdate={};
				for(var i=0;i<res.data.data.Dates.length;i++){
					monthdate[res.data.data.Dates[i].Date]=res.data.data.Dates[i].HourlyRate;
				}
				that.feeProps=monthdate;
				that.openPicker(monthdate);
			} else {
			}
		});	
	})
  },
  watch: {
    // 监听接口传值，收到即赋值展示
    feeProps (feeLis) {
      this.openPicker(feeLis)
    }
  }
}
</script>

<style lang="less" scoped>
.showDate{
	display: flex;
}
.MarginRight{
	margin-right: 10px;
}
.picestyle{
	color: #C42E2F;
}
</style>