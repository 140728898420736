<template>
	<div class="SelTypeBox BusinessBox">
		<div class="TypeBoxLi" :class="BusinessLayout==Item.Code?'active':''" data-Item="" @click="BusinessTypeCar(Item)" v-for="(Item,index) in $store.state.PriceSettings.business">
			<div>
				<h1>{{Item.Name.substring(0,Item.index)}}</h1>
				<p class="smalltxt">{{Item.Name.substring(Item.index)}}</p>
			</div>
		</div>
		<!--<div class="TypeBoxLi" :class="BusinessLayout=='Medium'?'active':''" @click="BusinessTypeCar('Medium')">
			<div>
				<h1>Medium</h1>
				<p class="smalltxt">office,Up to 10 people</p>
			</div>
		</div>
		<div class="TypeBoxLi" :class="BusinessLayout=='Large'?'active':''" @click="BusinessTypeCar('Large')">
			<div>
				<h1>Large</h1>
				<p class="smalltxt">office,Up to 20 people</p>
			</div>
		</div>
		<div class="TypeBoxLi" :class="BusinessLayout=='Bigtall'?'active':''" @click="BusinessTypeCar('Bigtall')">
			<div>
				<h1>Big or tall</h1>
				<p class="smalltxt">fumiture or shop fittings</p>
			</div>
		</div>
		<div class="TypeBoxLi" :class="BusinessLayout=='Lots'?'active':''" @click="BusinessTypeCar('Lots')">
			<div>
				<h1>Lots</h1>
				<p class="smalltxt">and lots of big stuffl</p>
			</div>
		</div>-->
	</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
	return {
		BusinessLayout:this.$store.state.processdata.GetItem?this.$store.state.processdata.GetItem.Code:''
	}
  },
  methods: {
  	BusinessTypeCar(e){
  		this.BusinessLayout=e.Code;
  		this.$emit('func',e);
  	}  	
  }
}
</script>

<style>
</style>