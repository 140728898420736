import axios from 'axios';
import Vue from 'vue';

//const HostApi = "http://52.63.98.48:8085/api";
//const HostApi = "https://imover.mangotechdemo.com.au/api";
const HostApi = "https://api.imover.com.au/api";


export const EventBus = new Vue();

const instance = axios.create();
const numbernew=1;
class Api{
	lastchannelmessage(index,channelid,userIdentity){
		let url = HostApi+'/twilio/channel/'+channelid+'/lastconsumedmessageindex/user/userIdentity?lastIndex='+index;
		return instance.post(url);
	}
	channelmessage(channelid,msg){
		let url = HostApi+'/twilio/channel/'+channelid+'/lastmessage?lastMessage='+msg;
		return instance.post(url);
	}
	indexInfo(req){
		let url = HostApi+'/booking/basicdata';
		return instance.get(url);
	}
	monthdate(moveSizeId){
		let url = HostApi+'/booking/dates/'+moveSizeId;
		return instance.get(url);
	}
	quickcontact(req){
		let url = HostApi+'/booking/quickcontact';
		return instance.post(url,req);
	}
	contactSubmit(req,quoteType,submitType){
		let url = HostApi+'/booking/v2/hold/'+quoteType+'/'+submitType;
		return instance.post(url,req).then(res=>{
			return res;
		});
	}
	newSubmit(req,quoteType,submitType,orderId){
		let url = HostApi+'/booking/v2/submit/'+quoteType+'/'+submitType+'?orderId='+orderId;
		return instance.post(url,req).then(res=>{
			return res;
		});
	}
	submit(req,quoteType,submitType){
		let url = HostApi+'/booking/submit/'+quoteType+'/'+submitType;
		return instance.post(url,req).then(res=>{
			return res;
		});
	}
	async quotepice(req){
		let url = HostApi+'/booking/quickquote';
		return await instance.post(url,req);
	}
	async fullpice(req){
		let url = HostApi+'/booking/fullprice';
		return await instance.post(url,req);
	}
	getorderInfo(orderId){
		let url = HostApi+'/booking/get/'+orderId;
		return instance.get(url);
	}
	getposition(taskId){
		let url = HostApi+'/task/vehicle/location/'+taskId;
		return instance.get(url);
	}
	gettoken(bookid){
		let url = HostApi+'/twilio/token/refresh/'+bookid;
		return instance.get(url);
	}
	getpositionInfo(taskId){
		let url = HostApi+'/task/get/info/'+taskId;
		return instance.get(url);
	}
	getcode(codeid){
		let url = HostApi+'/task/coupon/get/'+codeid;
		return instance.get(url);
	}
	getchannels(bookid){
		let url = HostApi+'/twilio/channel/init/'+bookid;
		return instance.get(url);
	}
	getarrivetime(bookid){
		let url = HostApi+'/task/'+bookid+'/arrivetime';
		return instance.get(url);
	}
	getscore(taskId,req){
		let url = HostApi+'/task/score/'+taskId+'?score='+req;
		return instance.post(url);
	}
}

const api = new Api();

export default api;
