<template>
	<div class="SelTypeBox">
		<div class="TypeBoxLi" :class="Layout==Item.Code?'active':''" @click="TypeCar(Item)" v-for="(Item,index) in $store.state.PriceSettings.pricehome">
			<div>
				<h1>{{Item.Name.substring(0,Item.index)}}</h1>
				<p>{{Item.Name.substring(Item.index)}}</p>
			</div>	
		</div>
		<!--<div class="TypeBoxLi" :class="Layout=='unit'?'active':''" @click="TypeCar('unit')">
			<div>
				<h1>1</h1>
				<p>bedroom unit/apartment</p>
				<p class="smalltxt">bedroom unit/apartment</p>
			</div>	
		</div>
		<div class="TypeBoxLi" :class="Layout=='house'?'active':''" @click="TypeCar('house')">
			<div>
				<h1>2-3</h1>
				<p>bedroom house/apartment</p>
				<p class="smalltxt">(medium storage unit up to 30m³)</p>
			</div>	
		</div>
		<div class="TypeBoxLi" :class="Layout=='bighouse'?'active':''" @click="TypeCar('bighouse')">
			<div>
				<h1>4+</h1>
				<p>bedroom house</p>
				<p class="smalltxt">(large storage unit up to 45m³)</p>
			</div>	
		</div>-->
	</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
	return {
		Layout:this.$store.state.processdata.GetItem?this.$store.state.processdata.GetItem.Code:''
	}
  },
  mounted(){
  },
  methods: {
  	TypeCar(e){
  		this.Layout=e.Code;
  		this.$emit('func',e);
  	}  	
  }
}
</script>

<style>
</style>