<template>
  <div class="BannerSwiper pr">
    <div class="nectar-video-wrap position-loaded" data-bg-alignment="center"
      style="width: 100%; "><video class="nectar-video-bg" width="1800" height="700"
        preload="auto" loop="" autoplay="" muted="" playsinline="">
        <source type="video/mp4" src="https://www.lovehomemove.com.au/wp-content/uploads/2021/12/imover_170.mp4">
      </video>
    </div>
    <div class="container zIndex2">
      <div class="BannerBox">
        <h1 class="fadeInUp animated" data-wow-delay=".3s">Melbourne Most Reliable Removalist Company</h1>
        <p class="fadeInUp animated" data-wow-delay=".5s">From small removals in Melbourne to large relocations, we can
          help! Call us for a quick moving quote!</br> We can help you move your new item into its new home in no time.
        </p>
        <a href="https://youtu.be/blqa6HHMlkE" target="_blank" class="BannerBtn fadeInUp animated" data-wow-delay=".8s">
          <img src="/img/bannercar.png">Watch video
        </a>
      </div>
    </div>
    <a href="javascript:;" class="bannerup fadeInUp animated zIndex2" data-wow-delay="1s" @click="scolltop()">
      <img src="/img/BannerIcon.png">Get a quick estimate
    </a>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    scolltop() {
      document.getElementById('estimate').scrollIntoView({ block: 'start', behavior: 'smooth' })
      //		document.getElementById("estimate").scrollIntoView();
      //		window.scrollTo(0,200);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.position-loaded{
  position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    height: 100%;
    padding-bottom: 0;
    overflow: hidden;
    z-index: 1;
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* z-index: -100; */
      background-position: 50%;
      background-size: cover;
      margin: auto;
      position: absolute;
      top: -100%;
      bottom: -100%;
      left: -100%;
      right: -100%;
    }
}
.zIndex2{
  z-index: 2;
}
.BannerSwiper{
  overflow: hidden;
}
</style>
