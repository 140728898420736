<template>
	<div class="ProcessBody">
		<div class="HomeBody NewPadd">
	  		<div class="container">
	  			<h1 class="ProcessLittle nomorlpadd">
	  				Move date&time
				</h1>
				<div class="pr SelBottom">
					<!--<a href="javascript:;" class="SelTime" @click="getFeeProps">Skip ></a>-->
					<Calendar :datePickerShow="datePickerShow" :feeProps="feeProps" @subimeTime="subimeTime"></Calendar>
				</div>
	    	</div>
	  	</div>
	</div>
</template>

<script>
import Calendar from '@/components/cell-month.vue'
import processbottom from '@/components/processbottom.vue'
export default {
	name: 'getdate',
	components: {
		Calendar,
	},
	data(){
		return{
	        datePickerShow: true,
			feeProps: {'2020-05-10':11,'2020-05-11':11},
			next:true,
	 	}
	},
	methods: {
		// 加载价格的接口。
		closeFee () {
	    	this.datePickerShow = false;	    	
	  	},
	    getFeeProps () {
    		this.feeProps={'2020-05-10':11,'2020-05-11':11};
	    	this.datePickerShow=true;
	  	},
	    Next(){
			this.$store.dispatch('getLayoutFun',6);
			this.$store.dispatch('setisshowFun',false);
    		this.$router.push({path:'/getquote/processfullpice',params:{}});
	    },
	    subimeTime(dateval){	    	
//  		this.$router.push({path:'/getquote/processfullpice',params:{}});
			this.$store.dispatch("getDateFun",dateval);
	    }
	},
	created() {
	},
	mounted() {
		this.$store.dispatch('getLayoutFun',5);
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
