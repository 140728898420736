<template>
	<div class="AnmartionBox">
		<img src="/img/Track.png" class="Track">
		<img src="/img/balloon.png" class="balloon">
		<img src="/img/smallcar.png" class="CarIcon">
		<div v-if="DefaultState">
			<img src="/img/housesmallone.png" class="smallhouseanmartion active">
		</div>
		<div class="NewPosition" v-else>
			<div :class="($store.state.Layout>6&&$store.state.parkingindex!=0&&$store.state.Layout<11)?'opacity':'showopcity'">
				<img src="/img/housesmallone.png" class="smallhouseanmartion" :class="(fromType=='House'||fromType=='')?'active':''" v-if="(houseType=='A2'||houseType=='A1')&&(fromfloor=='1F'||fromfloor=='0')" >
				<img src="/img/housesmalltwo.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="houseType=='A3'&&(fromfloor=='1F'||fromfloor=='0')">
				<img src="/img/housesmallthree.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="houseType=='A4'&&(fromfloor=='1F'||fromfloor=='0')">
				<img src="/img/househouseone.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="(houseType=='A2'||houseType=='A1')&&fromfloor=='2F'">
				<img src="/img/househousetwo.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="houseType=='A3'&&fromfloor=='2F'">
				<img src="/img/househousethree.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="houseType=='A4'&&fromfloor=='2F'">
				<img src="/img/housethreeone.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="(houseType=='A2'||houseType=='A1')&&fromfloor=='3F'">
				<img src="/img/housethreetwo.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="houseType=='A3'&&fromfloor=='3F'">
				<img src="/img/housethreethree.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="houseType=='A4'&&fromfloor=='3F'">
				<img src="/img/housefourone.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="(houseType=='A2'||houseType=='A1')&&fromfloor=='4F'">
				<img src="/img/housefourtwo.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="houseType=='A3'&&fromfloor=='4F'">
				<img src="/img/housefourthree.png" class="smallhouseanmartion" :class="fromType=='House'?'active':''" v-if="houseType=='A4'&&fromfloor=='4F'">
				<img src="/img/smallmentone.png" class="smallment" :class="fromType=='Apartment'?'active':''" v-if="fromfloor=='G'">
				<img src="/img/smallmenttwo.png" class="smallment" :class="fromType=='Apartment'?'active':''" v-if="fromfloor=='1F'">
				<img src="/img/smallmentthree.png" class="smallment" :class="fromType=='Apartment'?'active':''" v-if="fromfloor=='2F'">
				<img src="/img/smallmentfour.png" class="smallment" :class="fromType=='Apartment'?'active':''" v-if="fromfloor=='3FM'">
				<img src="/img/smallment.png" class="smallment" :class="fromType=='Apartment'?'active':''" v-if="fromfloor=='0'">
				<img src="/img/smallfacility.png" class="smallhouseanmartion" :class="fromType=='Storage Facility'?'active':''">
				<div class="containerIcon" v-if="$store.state.Layout>6&&$store.state.Layout<11">
				    <div class="chevron"></div>
				    <div class="chevron"></div>
				    <div class="chevron"></div>
				</div>
			</div>
			<div class="ImgCenter" v-if="!item">
				<div :class="($store.state.Layout>6&&$store.state.parkingindex!=(index+1)&&$store.state.Layout<11)?'opacity':'showopcitynew'"  v-for="(imgurl,index) in centerHouse" :key="index" >
					<img :src="imgurl" :style="'animation-delay:'+(index*0.5+1)+'s'">
					<div class="containerIcon center" v-if="$store.state.Layout>6&&$store.state.Layout<11">
					    <div class="chevron"></div>
					    <div class="chevron"></div>
					    <div class="chevron"></div>
					</div>
				</div>
			</div>
			<!--反向房源模型-->
			<div :class="($store.state.Layout>6&&$store.state.parkingindex!=($store.state.getlist.length-1)&&$store.state.Layout<11)?'opacity':'showopcity'">
				<img src="/img/housesmallone.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="(houseType=='A2'||houseType=='A1')&&(tofloor=='1F'||tofloor=='0')">
				<img src="/img/housesmalltwo.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="houseType=='A3'&&(tofloor=='1F'||tofloor=='0')">
				<img src="/img/housesmallthree.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="houseType=='A4'&&(tofloor=='1F'||tofloor=='0')">
				<img src="/img/househouseone.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="(houseType=='A2'||houseType=='A1')&&tofloor=='2F'">
				<img src="/img/househousetwo.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="houseType=='A3'&&tofloor=='2F'">
				<img src="/img/househousethree.png" class="smallhouseanmartion rotate"  :class="tohouse=='House'?'active':''" v-if="houseType=='A4'&&tofloor=='2F'">
				<img src="/img/housethreeone.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="(houseType=='A2'||houseType=='A1')&&tofloor=='3F'">
				<img src="/img/housethreetwo.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="houseType=='A3'&&tofloor=='3F'">
				<img src="/img/housethreethree.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="houseType=='A4'&&tofloor=='3F'">
				<img src="/img/housefourone.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="(houseType=='A2'||houseType=='A1')&&tofloor=='4F'">
				<img src="/img/housefourtwo.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="houseType=='A3'&&tofloor=='4F'">
				<img src="/img/housefourthree.png" class="smallhouseanmartion rotate" :class="tohouse=='House'?'active':''" v-if="houseType=='A4'&&tofloor=='4F'">
				<img src="/img/smallmentapmentone.png" class="smallment rotate" :class="tohouse=='Apartment'?'active':''" v-if="tofloor=='G'">
				<img src="/img/smallmentapmenttwo.png" class="smallment rotate" :class="tohouse=='Apartment'?'active':''" v-if="tofloor=='1F'">
				<img src="/img/smallmentapmentthree.png" class="smallment rotate" :class="tohouse=='Apartment'?'active':''" v-if="tofloor=='2F'">
				<img src="/img/smallmentapmentfour.png" class="smallment rotate" :class="tohouse=='Apartment'?'active':''" v-if="tofloor=='3FM'">
				<img src="/img/smallment_right.png" class="smallment rotate" :class="tohouse=='Apartment'?'active':''" v-if="tofloor=='0'">
				<img src="/img/smallfacility.png" class="smallhouseanmartion rotate" :class="tohouse=='Storage Facility'?'active':''">
				<div class="containerIcon right" v-if="$store.state.Layout>6&&$store.state.Layout<11">
				    <div class="chevron"></div>
				    <div class="chevron"></div>
				    <div class="chevron"></div>
				</div>
			</div>
			<img src="/img/businessSmall.png" class="businesssmall" :class="houseType=='B1'?'active':''">
			<img src="/img/businessMedium.png" class="businesssmall" :class="houseType=='B2'?'active':''">
			<img src="/img/businessLarge.png" class="businesssmall" :class="houseType=='B3'?'active':''">
			<img src="/img/businessBigtall.png" class="businesssmall" :class="houseType=='B4'?'active':''">
			<img src="/img/businessLots.png" class="businessLots" :class="houseType=='B5'?'active':''">
			<img src="/img/businessSmall.png" class="businesssmall rotate" :class="houseType=='B1'?'active':''">
			<img src="/img/businessMedium.png" class="businesssmall rotate" :class="houseType=='B2'?'active':''">
			<img src="/img/businessLarge.png" class="businesssmall rotate" :class="houseType=='B3'?'active':''">
			<img src="/img/businessBigtall.png" class="businesssmall rotate" :class="houseType=='B4'?'active':''">
			<img src="/img/businessLots.png" class="businessLots rotate" :class="houseType=='B5'?'active':''">
		</div>
		<div v-if="item">
			<img src="/img/item.png" class="item MarginLeftone fadeIn animated">
			<img src="/img/itemfour.png" class="item MarginLefttwo fadeInLeft animated">
			<img src="/img/itemone.png" class="item MarginLeftthree bounceInDown animated">
			<img src="/img/itemthree.png" class="item MarginLeftfour fadeInLeft animated">
			<img src="/img/itemtwo.png" class="item MarginLeftfive fadeInUp animated">
		</div>	
	</div>
</template>

<script>
	export default {
	  name: 'HelloWorld',
	  props: {
	  	DefaultState:Boolean,
	  	AnimationList:Array,
	    houseType:String,
	    item:Boolean,
	  },
	  data() {
			return {
				Layout: 1, 
				hread:1,
				centerHouse:[],
				comName:'processto',
				tohouse:'',
				fromType: '',
			    tofloor:'',
			    fromfloor:''
			}
		},
		computed: {
		},
		methods:{
			
		},
		mounted() {
			var  that=this;
//			console.log(this.$store.state.parkingindex);
			if(that.AnimationList.length>1){
				that.tohouse=that.AnimationList[that.AnimationList.length-1].house
			}
		},
		watch:{
			'AnimationList':{//监听数组变化,动画更新
				handler(newName, oldNam){
					var  that=this;
//					console.log(that.AnimationList);
//					console.log(that.AnimationList.length);
					if(that.AnimationList.length>0){
						that.fromType=that.AnimationList[0].house;
						that.fromfloor=that.AnimationList[0].floor;
					}
					if(that.AnimationList.length>1){
						that.tohouse=that.AnimationList[that.AnimationList.length-1].house
						that.tofloor=that.AnimationList[that.AnimationList.length-1].floor;
					}
					
//					console.log(that.fromType);
//					console.log(that.tohouse);
					if(that.AnimationList.length>2){
						var datalist=that.AnimationList.slice(1,-1);
//						console.log(datalist);
						that.centerHouse=[];
						for(var i=0;i<datalist.length;i++){	
							var imgurl='';
							switch(datalist[i].house){
								case 'Apartment':
					  				imgurl='/img/locationIcon_apartment.png'
					  				break;
				  				case 'House':
					  				imgurl='/img/locationIcon_house.png'
					  				break;
				  				case 'facility':
					  				imgurl='/img/locationIcon_storageFacility.png'
					  				break;		  				
							}					
							that.centerHouse.push(imgurl);						
						}
					}else{
						that.centerHouse=[];
					}
				},
				immediate: true,
				deep: true
			}
		}
	}
</script>

<style>
	.containerIcon {
	    position: absolute;
	    width: 24px;
	    height: 24px;
	    display: none;
	    left: 50%;
	    margin-left: -240px;
	    bottom: -50px;
	}
	.containerIcon.right{
		position: absolute;
	    width: 24px;
	    height: 24px;
	    display: none;
	    right: 50%;
	    margin-right: -280px;
	    bottom: -50px;
	    left: initial;
	    margin-left: initial;
	}
	.containerIcon.center{
		position: absolute;
	    width: 24px;
	    height: 24px;
	    left: 50%;
	    margin-left: -8px;
	    bottom: -75px;
	}
	    
	.showopcitynew{
		position: relative;
		/*width: 35px;
		margin-right: 15px;*/
	}
	.showopcity{
		position: relative;
	}
	
	.showopcitynew .containerIcon,.showopcity .containerIcon{
		display: block;
	}
	.chevron {
	    position: absolute;
	    width: 15px;
	    height: 2px;
	    opacity: 0;
	    transform: scale3d(0.5, 0.5, 0.5);
	    animation: move 1.5s ease-out infinite;
	}
	.chevron:first-child {
	    animation: move 1.5s ease-out 0.5s infinite;
	}
	.chevron:nth-child(2) {
	    animation: move 1.5s ease-out 1s infinite;
	}
	.chevron:before,
	.chevron:after {
	    content: ' ';
	    position: absolute;
	    top: 0;
	    height: 100%;
	    width: 51%;
	    background: #D22E35;
	}
	.chevron:before {
	    left: 0;
	    transform: skew(0deg, -30deg);
	}
	.chevron:after {
	    right: 0;
	    width: 50%;
	    transform: skew(0deg, 30deg);
	}
	@keyframes move {
	    25% {
	        opacity: 1;
	    }
	    33% {
	        opacity: 1;
	        transform: translateY(-10px);
	    }
	    67% {
	        opacity: 1;
	        transform: translateY(-15px);
	    }
	    100% {
	        opacity: 0;
	        transform: translateY(-20px) scale3d(0.5, 0.5, 0.5);
	    }
	}
	.text {
	    display: block;
	    margin-top: 75px;
	    margin-left: -30px;
	    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
	    font-size: 12px;
	    color: #fff;
	    text-transform: uppercase;
	    white-space: nowrap;
	    opacity: .25;
	    animation: pulse 2s linear alternate infinite;
	}
	@keyframes pulse {
	    to {
	        opacity: 1;
	    }
	}
</style>