<template>
	<div class="ProcessBody">
		<div class="HomeBody NewPadd">
	  		<div class="container">
				<div v-if="housetype=='home'">
					<div v-if="list[index].house=='House'">
			  			<h1 class="ProcessLittle nomorlpadd">
			  				Your {{processdata.inputtextall}} {{processdata.house}}
						</h1>
						<p class="ProcessSubheading">
							How many storeys?
						</p>
						<div class="Processzero">
							<a href="javascript:;" @click="ProcessBtn('1F')" :class="list[index].floor=='1F'?'active':''">
								1
							</a>
							<a href="javascript:;" @click="ProcessBtn('2F')" :class="list[index].floor=='2F'?'active':''">
								2
							</a>
							<a href="javascript:;" @click="ProcessBtn('3F')" :class="list[index].floor=='3F'?'active':''">
								3
							</a>
							<a href="javascript:;" @click="ProcessBtn('4F')" :class="list[index].floor=='4F'?'active':''">
								4
							</a>
						</div>
					</div>
					<div v-else-if="list[index].house=='Apartment'">
			  			<h1 class="ProcessLittle nomorlpadd">
			  				Your {{processdata.inputtextall}} {{processdata.house}}
						</h1>
						<p class="ProcessSubheading">
							Which floor are you on?
						</p>
						<div class="Processzero">
							<a href="javascript:;" @click="ProcessBtn('G')"  :class="list[index].floor=='G'?'active':''">
								G
							</a>
							<a href="javascript:;" @click="ProcessBtn('1F')"  :class="list[index].floor=='1F'?'active':''">
								1
							</a>
							<a href="javascript:;" @click="ProcessBtn('2F')"  :class="list[index].floor=='2F'?'active':''">
								2
							</a>
							<a href="javascript:;" @click="ProcessBtn('3FM')"  :class="list[index].floor=='3FM'?'active':''">
								3+
							</a>
						</div>
					</div>
				</div>
	  			<div v-if="housetype=='office'">
		  			<h1 class="ProcessLittle nomorlpadd" >
		  				Your {{processdata.inputtextall}} {{processdata.house}}
					</h1>
					<p class="ProcessSubheading">
						Which floor are you on?
					</p>
					<div class="Processzero">
						<a href="javascript:;" @click="ProcessBtn('G')"  :class="list[index].floor=='G'?'active':''">
							G
						</a>
						<a href="javascript:;" @click="ProcessBtn('1F')"  :class="list[index].floor=='1F'?'active':''">
							1
						</a>
						<a href="javascript:;" @click="ProcessBtn('2F')"  :class="list[index].floor=='2F'?'active':''">
							2
						</a>
						<a href="javascript:;" @click="ProcessBtn('3FM')"  :class="list[index].floor=='3FM'?'active':''">
							3+
						</a>
					</div>
				</div>
	    	</div>
	  	</div>
	</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
		return {
			housetype:'',
			list:[],
			housetype:0,
			Layout: 1, 
			hread:1,
			comName:'processto',
			processdata:{}
		}
	},
	methods:{
		ProcessBtn(floortype){
//			this.$emit('func',fromtype);
//			this.$parent.fatherfloorMethod(floortype);
			this.$store.dispatch('fatherfloorMethodFun',floortype);
		}
	},
	mounted() {
		this.housetype=this.$store.state.processdata.SelHouse;
	    this.list=this.$store.state.getlist;
	    this.index=this.$store.state.parkingindex;
		this.processdata=this.$store.state.getlist[this.$store.state.parkingindex];
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
