import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router' 

Vue.use(Vuex)

export default new Vuex.Store({
  state: sessionStorage.getItem("state") ? JSON.parse(sessionStorage.getItem("state")):{
  	isshowbottom:false,//控制动画部分显示
		hreadnum:1,//头部流程展示
		Layout: 1,
		hread: 1,
		hreadnum:1,
		numsize:0,
  	processdata:{
			SelHouse: '',
			GetHouseType: '',
		},		
		Layout: 1,
		subimeState:false,//判断提交按钮是否展示
		// 判断停止按钮是否展示
		StopAddress:false,
  	getlist:[
			{
				house:'',
				floor:'0',
				name: "Suburb or address", 
				inputtext: "", 
				id: 0,
				rice:"",
				docklands:null,
				docklandsstate:false,
			},
			{
				house:'',
				floor:'0',
				name: "Suburb or address", 
				inputtext: "", 
				id: 1,
				rice:"",
				docklands:null,
				docklandsstate:false,
			},
		],//提交数据
		Timedata:{},//时间数据
		//第一步选择的房子还是商业，类型
		DefaultAnimationState:true,
		parkingindex:0,
		moredata:false,
		getBusinessItem:false,//商业物件
		getItem:false,//住宅物件
		progressicon:true,
		contact:{},//联系方式
		PriceSettings:{},//价格设置
		getItemList:[],//物件集合
		getItemListitem:[],//物件集合
		getBusinessItemList:[],//物件集合
		getBusinessItemListitem:[],//物件集合
		orderId:0,
		continuenum:0,
		fullEdit:false,
  },
  mutations: {
	fullItemEdit(state,data){
	  state.fullEdit=data;
	},
  	getlistopera(state,data){
  		state.getlist=data;
  		state.moredata=false;
  		state.Timedata={};
			state.getBusinessItem=false;
			state.getItem=false;
			state.contact={};
  	},
  	getsubimeState(state,data){//修改提交按钮是否展示
			state.subimeState=data;
  	},
  	getStopAddress(state,data){//修改停止按钮是否展示
			state.StopAddress=data;
  	},
  	setisshow(state,isshow){
  		state.isshowbottom=isshow;
  	},
  	getMsgFormSon(state,data) {
			state.processdata.SelHouse=data.SelHouse;
			state.processdata.GetHouseType=data.GetHouseType;
			state.processdata.GetItem=data.GetItem;
			var SelhouseNew = data.SelHouse;
			if(SelhouseNew == 'home') {
				state.Layout = 2;
				state.getlist=[{
					house:'',
					floor:'0',
					name: "Suburb or address", 
					inputtext: "", 
					inputtextall:"",
					id: 0,
					writing:'from',
					rice:"",
					docklands:null,
					getIndex:1,
					exitstate:true,
					docklandsstate:false, 
					subimebtn:false
				}];
			} else if(SelhouseNew == 'office') {
				state.Layout = 2;
				state.getlist=[{
					house:'office',
					floor:'0',
					name: "Suburb or address", 
					inputtext: "", 
					inputtextall:"",
					id: 0,
					writing:'from',
					rice:"",
					docklands:null,
					getIndex:2,
					exitstate:true,
					subimebtn:false,
					docklandsstate:false,
					housetype:4,
				}];
//				state.getlist[0].housetype = 4;
//				state.getlist[0].house = 'Business';
//				state.getlist[1].housetype = 4;
//				state.getlist[1].house = 'Business';
			}
			state.hreadnum=2;
		},
		getMsgFormfrom(state,data) {				
			state.Layout = 3;				
			if(data=="Apartment"){
				state.getlist[0].floor='0';
			}
			var housertype;
			switch(data){
  				case 'House':
  				  housertype = 1;
  				  break;
  				case 'Apartment':
  				  housertype = 2;
  				  break;
  				case 'facility':
  				  housertype = 3;
  				  break;
  			}
			state.getlist[0].housetype = housertype;
			state.getlist[0].house = data;
		},
		getMsgFormto(state,data) {
				state.Layout = 4;
				if(data=="Apartment"){
					state.getlist[1].floor='0';
				}
				var housertype;
				switch(data){
	  				case 'House':
	  				  housertype = 1;
	  				  break;
	  				case 'Apartment':
	  				  housertype = 2;
	  				  break;
	  				case 'facility':
	  				  housertype = 3;
	  				  break;
	  			}
				state.getlist[1].housetype = housertype;
				state.getlist[1].house = data;
		},
		getMsgAddress(state,data) {
			state.DefaultAnimationState=false;
			state.getlist=data;
		},
		processfullpice(state,data) {
			state.submitType=data;
			state.Layout = 7;
			state.hread = 2;
			state.parkingindex=0;
			state.moredata=true;
		},
		getsubmitType(state,data) {
			state.submitType=data;
		},
		getLayout(state,data){
			state.Layout=data;
		},
		getHread(state,data){
			state.hread=data;
		},
		getparkingindex(state,data){
			state.parkingindex=data;
		},
		fatherMethod(state,data){
			state.getlist[state.parkingindex].rice=data;
			if(state.getlist[state.parkingindex].housetype==3){
				state.getlist[state.parkingindex].floor='0';
				state.getlist[state.parkingindex].docklands=null;
				state.parkingindex++;					
				if(state.parkingindex<state.getlist.length){
					state.Layout = 100;
					setTimeout(function(){
						state.Layout = 7;
						router.push('/getquote/processparking');
					},500)
				}else{
					if(state.processdata.SelHouse=='office'){
						state.Layout = 12;
						if(state.fullEdit){
							router.push('/fullprice');
						}else{
							router.push('/getquote/processbusinessitems');
						}
					}else{
						state.Layout = 11;
						if(state.fullEdit){
							router.push('/fullprice');
						}else{
							router.push('/getquote/processitems');
						}
					}
				}										
			}else{
				state.Layout = 8;
				router.push('/getquote/processhousefloor');
			}
		},
		fatherfloorMethod(state,data){
			state.getlist[state.parkingindex].floor=data;
			state.fromfloor=data;
			console.log(data);
			if(state.getlist[state.parkingindex].house=='Apartment'||state.processdata.SelHouse=='office') {
				state.Layout = 14;
				router.push('/getquote/processdocklands');
			} else {
				state.getlist[state.parkingindex].docklands=null;
				state.parkingindex++;
				if(state.parkingindex<state.getlist.length){
					state.Layout = 7;
					router.push('/getquote/processparking');
				}else{
					if(state.processdata.SelHouse=='office'){
						state.Layout = 12;
						if(state.fullEdit){
							router.push('/fullprice');
						}else{
							router.push('/getquote/processbusinessitems');
						}
					}else{
						state.Layout = 11;
						if(state.fullEdit){
							router.push('/fullprice');
						}else{
							router.push('/getquote/processitems');
						}
					}
				}
			}
		},
		fatherDockMethod(state,data){
				state.getlist[state.parkingindex].docklands=data;
				state.getlist[state.parkingindex].docklandsstate=true;
				state.parkingindex++;
				if(state.parkingindex<state.getlist.length){
					state.Layout = 7;
					router.push('/getquote/processparking');
				}else{
					if(state.processdata.SelHouse=='office'){
						state.Layout = 12;
						if(state.fullEdit){
							router.push('/fullprice');
						}else{
							router.push('/getquote/processbusinessitems');
						}
					}else{
						state.Layout = 11;
						if(state.fullEdit){
							router.push('/fullprice');
						}else{
							router.push('/getquote/processitems');
						}
					}
				}	
			},
			getDateMethod(state,data){
				state.Timedata=data;
			},
			getOnlyDateMethod(state,data){
				state.Timedata.onlytime=data;
			},
			getBusinessitem(state,data){
				state.getBusinessItemList=data.ItemsTxt;
				state.getBusinessItemListitem=data.Itembox;
				state.getBusiness=true;			
			},
			getitemore(state,data){
				state.getItemList=data.ItemsTxt;
				state.getItemListitem=data.Itembox;
				state.getItem=true;			
			},
			getContact(state,data){
				state.contact=data;			
			},
			getOrderId(state,data){
				state.orderId=data;			
			},
			getContinue(state,data){
				state.continuenum=data;			
			},
			progressicon(state,data){
				state.progressicon=data;			
			},
			getBasicsinfo(state,data){
				var pricehome=[];
				var business=[];
				for(var i=0;i<data.PriceSettings.length;i++){
					if(data.PriceSettings[i].Code.indexOf('A')!=-1){
						let index=data.PriceSettings[i].Name.indexOf(" ");
						data.PriceSettings[i].index=index;
						pricehome.push(data.PriceSettings[i]);
					}else{
						let index=data.PriceSettings[i].Name.indexOf(" ");
						data.PriceSettings[i].index=index;
						business.push(data.PriceSettings[i]);
					}
				}
				data.pricehome=pricehome;
				data.business=business;
				state.PriceSettings=data;			
			},
  },
  actions: {
  	getlistoperaFun(context,data){
  		context.commit("getlistopera",data);
  	},
  	setisshowFun(context,data){
  		context.commit("setisshow",data);
  	},
  	getMsgFormSonFun(context,data){
  		context.commit("getMsgFormSon",data);
  		
//		this.$store.dispatch("getMsgFormSon",data);调用示例
  	},//搬家是房源还是商业规模选择
  	getMsgFormfromFun(context,data){
  		context.commit("getMsgFormfrom",data);
  	},//搬家起点类型
  	getMsgFormtoFun(context,data){
  		context.commit("getMsgFormto",data);
  	},//搬家起点类型
  	getMsgAddressFun(context,data){
  		context.commit("getMsgAddress",data);
  	},//搬家地址编写
  	getLayoutFun(context,data){
  		context.commit("getLayout",data);
  	},//编辑Layout
  	getHreadFun(context,data){
  		context.commit("getHread",data);
  	},//编辑Hread
  	getparkingindexFun(context,data){
  		context.commit("getparkingindex",data);
  	},//编辑parkingindex
  	processfullpiceFun(context,data){
  		context.commit("processfullpice",data);
  	},//编辑
  	fatherMethodFun(context,data){//填写距离
  		context.commit("fatherMethod",data);
  	},
  	getsubimeStateFun(context,data){//提交
  		context.commit("getsubimeState",data);
  	},
  	getStopAddressFun(context,data){//停止修改
  		context.commit("getStopAddress",data);
  	},
  	fatherfloorMethodFun(context,data){//楼层
  		context.commit("fatherfloorMethod",data);
  	},
  	fatherDockMethodFun(context,data){//是否有电梯
  		context.commit("fatherDockMethod",data);
  	},
  	getDateFun(context,data){// 存时间
  		context.commit("getDateMethod",data);
  	},
  	getOnlyDateMethodFun(context,data){// 存时间上午或者下午
  		context.commit("getOnlyDateMethod",data);
  	},
  	getBusinessitemFun(context,data){//存商业物件
  		context.commit("getBusinessitem",data);
  	},
  	getitemFun(context,data){//存住宅物件
  		context.commit("getitemore",data);
  	},
  	getContactFun(context,data){//存联系方式
  		context.commit("getContact",data);
  	},
  	getBasicsinfoFun(context,data){//存初始化数据
  		context.commit("getBasicsinfo",data);
  	},
	getOrderIdFun(context,data){//存OrderId
  		context.commit("getOrderId",data);
  	},
  	getContinueFun(context,data){//存fullquote
  		context.commit("getContinue",data);
  	},
	getsubmitTypeFun(context,data){//存submitType
  		context.commit("getsubmitType",data);
  	},
  	progressiconFun(context,data){//隐藏左右按钮
  		context.commit("progressicon",data);
  	},
  },
  modules: {
  },
  watch:{
		'$store.state.Layout':function(newFlag, oldFlag){
			if(id==100){
				return;
			}
			state.hreadnum=parseInt(id);
			if(id!=3&&id!=6){
				if(state._isMobile()){
					state.numsize=state.numsize-180;
				}					
			}		
		}
	}
})
