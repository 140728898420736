<template>
	<div class="ProcessBody">
		<div class="HomeBody NewPadd">
	  		<div class="container">
	  			<div class="ContBoxRight">
					<h1>Contact</h1>
					<div class="ContBoxFrom">
						<div class="frominput" :class="errortxt=='Contact'?'redinput':''">
  							<p>Name<span>*</span></p>
  							<input type="text" v-model="contact.Contact">
  							<p class="red">{{erroriponetxt}}</p>
  						</div>
  						<div class="frominput" :class="errortxt=='Email'?'redinput':''">
  							<p>Email<span>*</span></p>
							<input type="text" v-model="contact.Email">
							<p class="red">{{erroriponetxt}}</p>
  						</div>
  						<div class="frominput" :class="errortxt=='Phone'?'redinput':''">
  							<p>Phone<span>*</span></p>
							<input type="text" v-model="contact.Phone">
							<p class="red">{{erroriponetxt}}</p>
  						</div>
  						<div class="frominput">
  							<p>Where did you find us?</p>
							<select class="public-choice" v-model="contact.CustomerSourceId" @change="getCouponSelected"> 
							    <option :value="coupon.value" v-for="coupon in couponList" >{{coupon.text}}</option>                                    
							</select>
  						</div>
  						<div class="frominput">
  							<p>Your message</p>
							<textarea  class="Rightext" v-model="contact.Remark"></textarea>
  						</div>
						<a href="javascript:;" class="RightBtn" @click="next()">
							Next
						</a>
					</div>
				</div>
	    	</div>
	  	</div>
	</div>
</template>

<script>
import api from '@/api'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
  	return{
  		errortxt:'',
  		erroriponetxt:'This field is required',
		couponList:[],
  		contact:{
  			Contact:'',
	  		Email:'',
	  		Phone:'',
	  		Remark:'',
			CustomerSourceId:''
  		}  		
  	}
  },
  watch: {
   	'contact.Contact':function(val){
        if(val&&val.length>0&&this.errortxt=='Contact'){
        	this.errortxt='';
  			this.erroriponetxt='';
        }
  	},
  	'contact.Email':function(val){
        if(val&&val.length>0&&this.errortxt=='Email'){
        	this.errortxt='';
  			this.erroriponetxt='';
        }
  	},
  	'contact.Phone':function(val){
        if(val&&val.length>0&&this.errortxt=='Phone'){
        	this.errortxt='';
  			this.erroriponetxt='';
        }
  	},
  },
  mounted(){
//	this.$store.dispatch('getBusinessitemFun',true);
	this.$store.dispatch('getLayoutFun',13);
	this.$store.dispatch('setisshowFun',false);
	this.contact=this.$store.state.contact;
	this.couponList=this.$store.state.PriceSettings.CustomerSourceItems;
	// this.contact.CustomerSourceId = this.couponList[0].value;
  },
  methods:{
	getCouponSelected(){
	},
  	next(){
//		console.log(this.$store.state.getItemList);
  		var that=this;
  		var regex = /^(\+?61|0)4\d{8}$/; 
  		var reg = new RegExp("^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$"); //正则表达式
  		if(that.contact.Contact==""||!that.contact.Contact){
  			that.errortxt='Contact';
  			that.erroriponetxt='This field is required';
  			return;
  		}
  		if(that.contact.Email==""||!that.contact.Email){
  			that.errortxt='Email';
  			that.erroriponetxt='This field is required';
  			return;
  		}
  		if(!reg.test(that.contact.Email)){
  			that.errortxt='Email';
  			that.erroriponetxt='Please enter a valid email';
  			return;
  		}
  		if(!regex.test(that.contact.Phone)){
  			that.errortxt='Phone';
  			that.erroriponetxt='Please enter a valid Phone';
  			return;
  		}
  		if(that.contact.Phone==""||!that.contact.Phone){
  			that.errortxt='Phone';
  			that.erroriponetxt='This field is required';
  			return;
  		}
		let submitType=this.$store.state.submitType;
		this.$store.dispatch('getContactFun',this.contact);
  		let quoteTypeNum;
  		if(this.$store.state.processdata.SelHouse=='home'){
  			quoteTypeNum=1;
  		}else{
  			quoteTypeNum=2;
  		}
  		let quoteType=quoteTypeNum;
		var req={};
		req.BookDateTime=this.$store.state.Timedata.pickedDate;
		req.TimePeroid=this.$store.state.Timedata.onlytime;
		// if(this.$store.state.Timedata.onlytime=="Afternoon"){
		// 	req.TimePeroid='am';
		// }else{
		// 	req.TimePeroid='pm';
		// }
		req.MoveSizeId=this.$store.state.processdata.GetHouseType;
		req.Contact=this.contact.Contact;
		req.Email=this.contact.Email;
		req.Phone=this.contact.Phone;
		req.Remark=this.contact.Remark;
		req.CustomerSourceId=this.contact.CustomerSourceId;
		req.Locations=[];
		req.Items=[];
		if(this.$store.state.getItemList){
			for(var itemsindex=0;itemsindex<this.$store.state.getItemList.length;itemsindex++){
				let Itemdata={};
				Itemdata.ItemId=this.$store.state.getItemList[itemsindex].id;
				Itemdata.Qty=this.$store.state.getItemList[itemsindex].num;
				req.Items.push(Itemdata);
			}
		}
		for(var i=0;i<this.$store.state.getlist.length;i++){//房源信息
			var Location={};
			let MoveTypeId='';
			switch(this.$store.state.getlist[i].house){
  				case 'Apartment':
  					MoveTypeId='M1';
  					break;
				case 'House':
  					MoveTypeId='M2';
  					break;
				case 'Storage Facility':
  					MoveTypeId='M3';
  					break;
  				default:
	  				MoveTypeId='M4';
	  				break;
  			}				
			Location.PlaceId=this.$store.state.getlist[i].newlocation.PlaceId;
			Location.Location=this.$store.state.getlist[i].newlocation.Location;
			Location.Address=this.$store.state.getlist[i].inputtextall;
			Location.Lat=this.$store.state.getlist[i].newlocation.Lat;
			Location.Lng=this.$store.state.getlist[i].newlocation.Lng;
			Location.PakingDistance=this.$store.state.getlist[i].rice;
			Location.Floor=this.$store.state.getlist[i].floor;
			Location.IsHaveDlevator=this.$store.state.getlist[i].docklands;
			Location.MoveTypeId=MoveTypeId;
			req.Locations.push(Location);
		}	
		var processfun;
		if(this.$store.state.orderId){
			req.ModifyContact=true;
			processfun=api.newSubmit(req,quoteType,submitType,this.$store.state.orderId);
		}else{
			processfun=api.contactSubmit(req,quoteType,submitType);
		}
		window.popUpFrame.Loading(processfun).then(res => {
			if(res.data.code == 1000) {
				this.$store.dispatch('getOrderIdFun',res.data.data.OrderId);
				if(submitType==2){
					if(that.$route.query.quotetype==0){
						that.$router.push({
							path: '/fullprice',				
							query:{}
						});
					}else{
						that.$router.push({
							path: '/fullprice',				
							query:{
								'quotetype':1
							}
						});
					}					
					return;
				}
				if(submitType==3){
					that.$store.dispatch('getContinueFun',1);
					that.$router.push({ 
						path: '/getquote/processfullpicelast',				
						query:{
							'quotetype':0
						}
					});
					return;
				}else{
					that.$router.push({
						path: '/bookingemailsucess',
						query: {
							'orderid':res.data.data.OrderId,
							'fullquote':1,
						}
					});
				}
			} else {
			}
		});
//		this.$router.push({ path: '/fullprice'});
  	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	h3 {
	  margin: 40px 0 0;
	}
	ul {
	  list-style-type: none;
	  padding: 0;
	}
	li {
	  display: inline-block;
	  margin: 0 10px;
	}
	.red{
		color: #D22E35;
		font-size: 14px;
		display: none;
	}
	.frominput textarea{
		font-family:'Arial';
		padding-top: 10px;
	}
	.redinput input,.redinput textarea{
		border-color:#D22E35;
	}
	.redinput .red{
		display: block;
	}
	.public-choice{
		border-radius: 2px;
		height: 60px;
		width: 100%;
		padding-left: 10px;
		margin-bottom: 5px;
		font-size: 18px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		background: transparent;
		outline: none;
		appearance:none;
		-moz-appearance:none;
		-webkit-appearance:none;
		/*在选择框的最右侧中间显示小箭头图片*/
		background: url(/img/upicon_31.jpg) no-repeat scroll 95% 26px transparent;
		background-size: 15px 9px;
		border: 1px solid rgba(29, 29, 29, 0.4);
	}
</style>
