<template>
	<div>
		<Calendar :datePickerShow="datePickerShow" :feeProps="feeProps" @feeClose="closeFee"></Calendar>
		<div @click="getFeeProps">dsdsds</div>
	</div>
</template>

<script>
	import Calendar from '@/components/cell-month.vue'
	export default {
		name: 'Home',
		components: {
			Calendar,
		},
		data(){
        	return{
	            datePickerShow: true,
      			feeProps: {'2020-03-10':11,'2020-03-11':11}
         	}
        },
		methods: {
			// 加载价格的接口。
			closeFee () {
	        	this.datePickerShow = false
	      	},
		    getFeeProps () {
		    	this.feeProps={'2020-03-10':11,'2020-03-11':11};
		    	this.datePickerShow=true;
		    }
		},
		created() {
		},
		mounted() {

		},
	}
</script>

<style>

</style>