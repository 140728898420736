<template>
	<div class="ProcessBody">
		<div class="HomeBody NewPadd">
	  		<div class="container">
	  			<div class="HreadPadd">
		  			<h1 class="ProcessLittle nomorlpadd">
		  				I need to move from
					</h1>
					<p class="ProcessSubheading" v-if="getlist.length>2">
						Drag and drop to change order
					</p>
				</div>
				<div class="AddressBox">
					<div class="AddressTxt">
						<div class="AddTxt" v-for="(element, idx) in sceentype">
							{{element}}
						</div>
					</div>
					<div class="AddressIcon">
						<div class="AddIcon" v-for="(element, idx) in getlist">
							<img src="/img/smallhouse.png" v-if="element.housetype==1" @click="showPopSelHouse(element.housertype,idx)">
							<img src="/img/smallment.png" v-else-if="element.housetype==2" @click="showPopSelHouse(element.housertype,idx)">
							<img src="/img/smallfacility.png" v-else-if="element.housetype==3" @click="showPopSelHouse(element.housertype,idx)">
							<img src="/img/businessSmall.png" v-else>
						</div>
					</div>
					<div class="AddressInput">
						<div class="InputBox" style="height:100%">
							<div class="TipsBox" v-if="getlist.length>2">
								Drag and drop to change order
							</div>
							<draggable class="HoverNewBox" :class="getlist.length>2?'active':''" style="height:100%" handle=".handle" v-bind="dragOptions">
								<div class="Input pr"  v-for="(element, idx) in getlist">
									<input type="text" :placeholder="element.name" v-model="element.inputtext">
									<a href="javascript:;" class="HoverIcon handle" v-if="getlist.length>2">
										<img src="/img/imover.jpg">
									</a>
									<a href="javascript:;" class="CloseIcon" @click="removeAt(idx)" v-if="getlist.length>2">
										<img src="/img/closebtn.jpg">
									</a>
								</div>	
							</draggable>	
						</div>
					</div>
					<div class="SwitchBox" v-if="getlist.length<3" @click="Switch">
						<a href="javascript:;"><img src="/img/Trans.jpg"></a>
					</div>
				</div>
				<a href="javascript:;" class="AddMoreInput" @click="add">+ Add more address</a>
				<div class="AddressSubmit">
					<a href="javascript:;" @click="Next()">Next</a>
				</div>
	    	</div>
	  	</div>
	  	<div class="PopSelHouse"  v-show="PopSelHouse">
	    	<div class="PopSelHouseCont">
	    		<h1>
	    			Choose home type
	    		</h1>
	    		<div class="SelHouseCheckBox">
	    			<div class="HouseCheckLi" :class="PopSelHouseId==1?'active':''" @click="HouseTypeBtn(1)">
	    				<div class="HouseImg">
	    					<img src="/img/smallhouse.png">
	    				</div>
	    				<p>
	    					House
	    				</p>
	    				<span class="check"></span>
	    			</div>
	    			<div class="HouseCheckLi" :class="PopSelHouseId==2?'active':''" @click="HouseTypeBtn(2)">
	    				<div class="HouseImg">
	    					<img src="/img/smallment.png">
	    				</div>
	    				<p>
	    					Apartment
	    				</p>
	    				<span class="check"></span>
	    			</div>
	    			<div class="HouseCheckLi" :class="PopSelHouseId==3?'active':''" @click="HouseTypeBtn(3)">
	    				<div class="HouseImg">
	    					<img src="../../public/img/smallfacility.png">
	    				</div>
	    				<p>
	    					Storage facility
	    				</p>
	    				<span class="check"></span>
	    			</div>
	    		</div>
	    		<div class="SelHouseBtn">
					<a href="javascript:;" @click="ConfirmHouseType">Confirm</a>
	    		</div>
	    	</div>
	    </div>
	</div>
</template>

<script>
	let id = 1;
	import draggable from "vuedraggable";
	export default {
	  name: "handle",
	  props:{
//	  	getlist:Array,
	  },
	  display: "Handle",
	  instruction: "Drag using the handle icon",
	  order: 5,
	  components: {
	    draggable
	  },
	  data() {
	    return {
	    	PopSelHouse:false,
	    	getlist:[],
	      sceentype:['From','To'],
	      housetype:[1,3],
	      PopSelHouseId:1,
	      PopSelHouseIndex:1,
	      dragging: false,
	      id:1
	    };
	  },
	  mounted(){
		this.getlist=this.$store.state.getlist;
	  },
	  computed: {
	    draggingInfo() {
	      return this.dragging ? "under drag" : "";
	    },
	    dragOptions() {
	      return {
	        animation: 200,
	        group: "description",
	        disabled: false,
	        ghostClass: "ghost"
	      };
	    }
	  },
	  methods: {
	  	Next(){
    		for(var i=0;i<this.getlist.length;i++){
    			this.getlist[i].sceentype=this.sceentype[i];
    		}
    		this.$store.dispatch("getMsgAddressFun",this.getlist);
    		this.$router.push({path:'/getquote/processdate',params:{}});
//	  		this.$emit('func',this.getlist);
	  	},
	    removeAt(idx) {
	      this.getlist.splice(idx, 1);
	      this.sceentype.splice(idx, 1);
	    },
	    showPopSelHouse(e,index){
    		this.PopSelHouse=!this.PopSelHouse;
    		this.PopSelHouseId=e;
    		this.PopSelHouseIndex=index;
	    },
	    HouseTypeBtn(type){
	    	this.PopSelHouseId=type;
	    },
	    ConfirmHouseType(){
	    	this.getlist[this.PopSelHouseIndex].housertype=this.PopSelHouseId;	    	
    		this.PopSelHouse=!this.PopSelHouse;
	    },
	    add() {
	      this.id++;
	      this.getlist.splice(1,0,{ house:'House',floor:'1',name: "Suburb or address", text: "", id: 0 ,housetype:1,rice:"",docklands:false,});
	      this.sceentype.splice(1,0,'Via');
	    },
	    Switch(){
	    	this.getlist[1] = this.getlist.splice(this.getlist[2], 1, this.getlist[1])[0];
	    }
	  }
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
.new{
	width: 50px;
	height: 50px;
	background: red;
	display: inline-block;
}
</style>
