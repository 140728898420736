
import store from '@/store';
import api from '@/api';
import router from '@/router' 

export default {
    Getsubmit(type,quotetype){
    	var that=this;
    	let quoteTypeNum;
  		if(store.state.processdata.SelHouse=='home'){
  			quoteTypeNum=1;
  		}else{
  			quoteTypeNum=2;
  		}
  		let quoteType=quoteTypeNum;
        var req={};
		req.BookDateTime=store.state.Timedata.pickedDate;
		req.TimePeroid=store.state.Timedata.onlytime;
		// if(store.state.Timedata.onlytime=="Afternoon"){
		// 	req.TimePeroid='am';
		// }else{
		// 	req.TimePeroid='pm';
		// }
		let submitType;
		if(type=='email'){
			submitType=1;
		}else{
			submitType=store.state.submitType;
		}
		req.MoveSizeId=store.state.processdata.GetHouseType;
		req.Contact=store.state.contact.Contact;
		req.Email=store.state.contact.Email;
		req.Phone=store.state.contact.Phone;
		req.CustomerSourceId=store.state.contact.CustomerSourceId;
		req.Remark=store.state.contact.Remark;
		req.Locations=[];
		req.Items=[];
		req.ModifyContact=false;
		if(store.state.getItemList){
			for(var itemsindex=0;itemsindex<store.state.getItemList.length;itemsindex++){
				let Itemdata={};
				Itemdata.ItemId=store.state.getItemList[itemsindex].id;
				Itemdata.Qty=store.state.getItemList[itemsindex].num;
				req.Items.push(Itemdata);
			}
		}
		for(var i=0;i<store.state.getlist.length;i++){//房源信息
			var Location={};
			let MoveTypeId='';
			switch(store.state.getlist[i].house){
				case 'Apartment':
					MoveTypeId='M1';
					break;
				case 'House':
					MoveTypeId='M2';
					break;
				case 'Storage Facility':
					MoveTypeId='M3';
					break;
				default:
	  				MoveTypeId='M4';
	  				break;
			}				
			Location.PlaceId=store.state.getlist[i].newlocation.PlaceId;
			Location.Location=store.state.getlist[i].newlocation.Location;
			Location.Address=store.state.getlist[i].inputtextall;
			Location.Lat=store.state.getlist[i].newlocation.Lat;
			Location.Lng=store.state.getlist[i].newlocation.Lng;
			Location.PakingDistance=store.state.getlist[i].rice;
			Location.Floor=store.state.getlist[i].floor;
			Location.IsHaveDlevator=store.state.getlist[i].docklands;
			Location.MoveTypeId=MoveTypeId;
			req.Locations.push(Location);
		}	
		window.popUpFrame.Loading(api.newSubmit(req,quoteType,submitType,store.state.orderId)).then(res => {
			if(res.data.code == 1000) {
				if(submitType==1){
					router.push({
						path: '/bookingemailsucess',
						query: {
							'orderid':res.data.data.OrderId
						}
					});
				}else{
					router.push({
						path: '/bookingsucess',
						query: {
							'orderid':res.data.data.OrderId
						}
					});
				}
			} else {
			}
		});
   },
    getUrlParam(name) {
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
		var r = window.location.search.substr(1).match(reg);  //匹配目标参数
		if (r != null) return unescape(r[2]);
		return null; //返回参数值
	},
	async getPice(type,quotetype){
		var req={};
		req.BookDateTime=store.state.Timedata.pickedDate;
		req.TimePeroid=store.state.Timedata.onlytime;
		// if(store.state.Timedata.onlytime=="Afternoon"){
		// 	req.TimePeroid='am';
		// }else{
		// 	req.TimePeroid='pm';
		// }		
		req.MoveSizeId=store.state.processdata.GetHouseType;
		req.Locations=[];
		if(type==2){
			req.Contact=store.state.contact.Contact;
			req.Email=store.state.contact.Email;
			req.Phone=store.state.contact.Phone;
			req.Remark=store.state.contact.Remark;
			req.Items=[];
			if(store.state.getItemList){
				for(var itemsindex=0;itemsindex<store.state.getItemList.length;itemsindex++){
					let Itemdata={};
					Itemdata.ItemId=store.state.getItemList[itemsindex].id;
					Itemdata.Qty=store.state.getItemList[itemsindex].num;
					req.Items.push(Itemdata);
				}
			}	
		}
		for(var i=0;i<store.state.getlist.length;i++){//房源信息
			var Location={};
			let MoveTypeId='';
			switch(store.state.getlist[i].house){
				case 'Apartment':
					MoveTypeId='M1';
					break;
				case 'House':
					MoveTypeId='M2';
					break;
				case 'Storage Facility':
					MoveTypeId='M3';
					break;
				default:
	  				MoveTypeId='M4';
	  				break;
			}				
			Location.PlaceId=store.state.getlist[i].newlocation.PlaceId;
			Location.Location=store.state.getlist[i].newlocation.Location;
			Location.Address=store.state.getlist[i].inputtextall;
			Location.Lat=store.state.getlist[i].newlocation.Lat;
			Location.Lng=store.state.getlist[i].newlocation.Lng;
			Location.PakingDistance=store.state.getlist[i].rice;
			Location.Floor=store.state.getlist[i].floor;
			Location.IsHaveDlevator=store.state.getlist[i].docklands;
			Location.MoveTypeId=MoveTypeId;
			req.Locations.push(Location);
		}	
		var res;
		if(type==2&&quotetype==0){
			res=await window.popUpFrame.Loading(api.fullpice(req));
		}else{
			res=await window.popUpFrame.Loading(api.quotepice(req));
		}
		if(res.data.code==1000){
			return res.data.data;
		}
	}
}